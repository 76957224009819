import React, { useEffect } from 'react'
import { STORAGE_EVENT } from '~/constants/storage'

interface IHookStorageProps {
  setValue:
    | React.Dispatch<React.SetStateAction<string | undefined>>
    | ((newValue: string) => void)
  storageKey: string
}

export const useStorage = ({ setValue, storageKey }: IHookStorageProps) => {
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (e.key === storageKey && e.newValue) {
        setValue(e.newValue)
      }
    }

    window.addEventListener(STORAGE_EVENT, handleStorage)
    return () => window.removeEventListener(STORAGE_EVENT, handleStorage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStorageValue = (storageKey: string) => {
    return window.localStorage.getItem(storageKey)
  }

  const setStorageValue = ({
    storageKey,
    newStorageValue,
    shouldDispatchStorageEvent = false,
  }: {
    storageKey: string
    newStorageValue: string
    shouldDispatchStorageEvent?: boolean
  }) => {
    window.localStorage.setItem(storageKey, newStorageValue)

    if (shouldDispatchStorageEvent) {
      window.dispatchEvent(
        new StorageEvent(STORAGE_EVENT, {
          key: storageKey,
          newValue: newStorageValue,
        }),
      )
    }
  }

  return {
    getStorageValue,
    setStorageValue,
  }
}
